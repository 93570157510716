'use strict'

import { FORBIDDEN, UNAUTHORIZED } from '../shared/constants/httpStatus'
import { SHOW_ALERT } from '../store/mutations-types'
import { LOGOUT } from '../store/modules/security/mutations-types'

/**
 * Mixin file with the methods to show error messages.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 11:47 p. m.
 */

const handleErrorMixin = {
  methods: {
    /**
     * Function to handle the application errors.
     *
     * @param error
     *
     * @author C Camilo Castellanos
     * @version 1.0.0
     * @since 19/12/2019 11:57 p. m.
     */
    $_handleErrorMixin_generateError (error) {
      if (error.response && (error.response.status === UNAUTHORIZED || error.response.status === FORBIDDEN)) {
        this.$store.commit(LOGOUT)
        this.$router.go({ name: 'Authentication' })
        return
      }

      console.error(error.response ? error.response : error)

      this.$store.commit(SHOW_ALERT, {
        type: 'error',
        text: this.$t('error_message.internal_server'),
        show: true
      })
    },
    $_handleErrorMixin_redirectPage (error, page) {
      this.$router.go({ name: page })
      this.$store.commit(SHOW_ALERT, {
        type: 'error',
        text: error.response.data.errorMessage,
        show: true
      })
    }
  }
}

export default handleErrorMixin
